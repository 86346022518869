@tailwind base;
@tailwind components;
@tailwind utilities;

@import './loader.scss';


@font-face {
  font-family: "sfpro";
  src: url("./assets/fonts/SF-Pro.ttf");
}